<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="p-3">
        <b-row align-h="around">
          <b-col cols="4">
            <inline-select
              id="search-module"
              ref="search-module"
              :value="data.search_module"
              :label="searchModule.label"
              :options="searchModule.options"
              :allow-empty="false"
              :multiple="false"
              :mode="$constants.FORM_MODE.EDIT"
              @changed="onChangeSearchModule"
              :required="true"
            />
          </b-col>
          <b-col cols="4">
            <inline-select
              id="group-columns"
              ref="group-columns"
              :value="data.group_columns"
              :label="groupColumns.label"
              :options="groupColumns.options"
              :allow-empty="false"
              :multiple="true"
              :mode="$constants.FORM_MODE.EDIT"
              @changed="onChangeGroupColumns"
              :required="true"
            />
          </b-col>
          <b-col cols="2">
            <b-container fluid>
              <b-row align-h="center" class="text-center">
                <b-col class="p-2">
                  <b-button
                    @click="onSearchClick"
                    style="width: 8em;"
                    :variant="
                      isUpdateRequired ? 'success' : 'outline-secondary'
                    "
                  >
                    <font-awesome-icon icon="sync" />
                  </b-button>
                </b-col>
                <b-col v-if="!hideResetButton" class="p-2">
                  <b-button
                    @click="onResetClick"
                    style="width: 8em;"
                    variant="outline-secondary"
                  >
                    <font-awesome-icon icon="backspace" />
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <duplicate-search-table
              ref="search-results"
              :module="selectedModule"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import DuplicateSearchTable from "./DuplicateSearchTable";
import InlineSelect from "@/components/InlineSelectMultiple";

const accountColumns = [
  "Account Name",
  "Chain",
  "Phone",
  "Email",
  "Website",
  "Country",
  "City",
  "State",
  "Category",
  "Assigned To"
];

const contactColumns = [
  "Contact Name",
  "Email",
  "Phone",
  "Mobile",
  "Title",
  "Account Name",
  "Assigned To"
];

export default {
  name: "DuplicateSearch",
  components: {
    InlineSelect,
    DuplicateSearchTable
  },

  data: function() {
    return {
      isUpdateRequired: false,
      hideResetButton: false,
      formError: "",
      searchModule: {
        id: "select:search_module",
        label: "Search module",
        changed: false,
        readonly: false,
        options: [
          { id: "Accounts", label: "Accounts" },
          { id: "Contacts", label: "Contacts" }
        ]
      },
      groupColumns: {
        id: "select:group_columns",
        label: "Group columns",
        changed: false,
        readonly: false,
        options: []
      },
      data: {
        search_module: {
          id: "Accounts",
          label: "Accounts"
        },
        group_columns: []
      }
    };
  },
  computed: {
    selectedModule() {
      return this.data.search_module.id;
    }
  },
  created() {},

  mounted() {
    this.setAccountsColumns();
  },
  methods: {
    setAccountsColumns() {
      this.groupColumns.options = accountColumns.map(c => ({
        id: c,
        label: c
      }));
    },
    setContactColumns() {
      this.groupColumns.options = contactColumns.map(c => ({
        id: c,
        label: c
      }));
    },
    onChangeSearchModule(id, val) {
      this.data.search_module = val;
    },
    onChangeGroupColumns(id, val) {
      this.data.group_columns = val;
    },
    onSearchClick() {
      if (!this.isSearchAllowed()) {
        this.$form.makeToastError(this.formError);
        return;
      }
      /*
      let payload = {
        module: this.data.search_module,
        columns: this.data.group_columns
      };*/
      let payload = {
        groupby: this.data.group_columns.map(c => c.id)
      };

      this.$refs["search-results"].getData(payload);
    },
    onResetClick() {
      //this.data.search_module = {};

      this.data.group_columns = [];
    },

    isSearchAllowed() {
      this.formError = "";

      if (JSON.stringify(this.data.search_module) === "{}") {
        this.$refs["search-module"].test();
        this.formError = "Please specify search module";
        return false;
      }

      if (!this.data.group_columns.length) {
        this.$refs["group-columns"].test();
        this.formError = "Please specify at least one grouping column";
        return false;
      }

      return true;
    }
  },
  watch: {
    "data.search_module"(newVal) {
      this.data.group_columns = [];

      this.$refs["search-results"].reset();

      if (newVal.id === "Accounts") this.setAccountsColumns();

      if (newVal.id === "Contacts") this.setContactColumns();
    }
  }
};
</script>

<style></style>
