var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-container', {
    staticClass: "p-3",
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    attrs: {
      "align-h": "around"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('inline-select', {
    ref: "search-module",
    attrs: {
      "id": "search-module",
      "value": _vm.data.search_module,
      "label": _vm.searchModule.label,
      "options": _vm.searchModule.options,
      "allow-empty": false,
      "multiple": false,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onChangeSearchModule
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('inline-select', {
    ref: "group-columns",
    attrs: {
      "id": "group-columns",
      "value": _vm.data.group_columns,
      "label": _vm.groupColumns.label,
      "options": _vm.groupColumns.options,
      "allow-empty": false,
      "multiple": true,
      "mode": _vm.$constants.FORM_MODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onChangeGroupColumns
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "2"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-row', {
    staticClass: "text-center",
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    staticClass: "p-2"
  }, [_c('b-button', {
    staticStyle: {
      "width": "8em"
    },
    attrs: {
      "variant": _vm.isUpdateRequired ? 'success' : 'outline-secondary'
    },
    on: {
      "click": _vm.onSearchClick
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "sync"
    }
  })], 1)], 1), !_vm.hideResetButton ? _c('b-col', {
    staticClass: "p-2"
  }, [_c('b-button', {
    staticStyle: {
      "width": "8em"
    },
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.onResetClick
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "backspace"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('duplicate-search-table', {
    ref: "search-results",
    attrs: {
      "module": _vm.selectedModule
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }