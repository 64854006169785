var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "mode": "client",
      "name": "custom_table_duplicates",
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-secondary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.toggleChilds(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "tasks"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_vm.module === 'Accounts' ? _c('accounts-table', {
          ref: "accounts-table",
          attrs: {
            "selectable": true,
            "autoload": true,
            "show-add-button": true,
            "hide-settings-bar": true,
            "filter": _vm.getFilter4Child(props.row)
          }
        }) : _vm._e(), _vm.module === 'Contacts' ? _c('contacts-table', {
          ref: "contacts-table",
          attrs: {
            "selectable": true,
            "autoload": true,
            "show-add-button": false,
            "hide-settings-bar": true,
            "filter": _vm.getFilter4Child(props.row)
          }
        }) : _vm._e()], 1);
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }