<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      mode="client"
      :name="`custom_table_duplicates`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    >
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-secondary btn-sm"
            @click="toggleChilds(props.row['ID'])"
          >
            <font-awesome-icon icon="tasks" />
          </button>
        </div>
      </div>

      <span slot-scope="props" slot="child_row">
        <accounts-table
          v-if="module === 'Accounts'"
          ref="accounts-table"
          :selectable="true"
          :autoload="true"
          :show-add-button="true"
          :hide-settings-bar="true"
          :filter="getFilter4Child(props.row)"
        />

        <contacts-table
          v-if="module === 'Contacts'"
          ref="contacts-table"
          :selectable="true"
          :autoload="true"
          :show-add-button="false"
          :hide-settings-bar="true"
          :filter="getFilter4Child(props.row)"
        />
      </span>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";
import AccountsTable from "@/views/RelationshipManagement/AccountsTable.vue";
import ContactsTable from "@/views/RelationshipManagement/ContactsTable.vue";

export default {
  name: "DuplicateSearchTable",
  props: {
    module: {
      type: String,
      default: ""
    }
  },
  components: {
    TableCustom,
    AccountsTable,
    ContactsTable
  },
  data: function() {
    return {
      isLoading: false,
      rawData: {},
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          columns: [],
          filterable: [],
          uniqueKey: "ID",
          hideUniqueKey: true,
          showChildRowToggler: true,
          filterByColumn: true,
          perPage: 50,
          perPageValues: [],
          showCustomActions: false,
          showActions: false,
          showChildRows: true
          //selectableRows: this.selectable
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    reset() {
      this.dataTable.dataSet = [];
    },
    async getData(payload) {
      let apiUrl = "";
      let self = this;

      if (this.module === "Accounts") apiUrl = "accounts";

      if (this.module === "Contacts") apiUrl = "contacts";

      this.dataTable.isLoading = true;

      this.appliedFilters = payload;

      //payload.serverOptions = options || self.$refs.dataTable.serverOptions;

      return this.$api
        .post(apiUrl, payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          response.data.forEach(row => {
            //add unique key for each row to allow childs expanding
            row["ID"] = this.$helpers.uuidv4();
          });

          self.dataTable.dataSet = response.data;

          if (response.count === 0) return;

          if (self.$refs.dataTable)
            self.$refs.dataTable.setTotalPaginationRows(response.count);
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    toggleChilds(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    getFilter4Child(row) {
      let f = [];
      for (const column in row) {
        if (["ID", "Count"].includes(column) || !row[column]) continue;

        f.push({ name: column, value: row[column] });
      }

      return f;
    }
  }
};
</script>

<style scoped></style>
